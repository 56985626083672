import React from 'react';

import { trackEvent } from '../utils/analytics';

import Cookies from 'js-cookie';

/**
 * Wrapper class for generating a download link that will also fire
 * a Google Analytics "download" event.
 */
class DownloadLink extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    if (Cookies.get('cookie-notice-acknowledged') === 'yes') { 
      trackEvent('download', 'click', this.props.to);
    }
  }

  render() {
    let {to, text} = this.props;
    return (
      <a href={to} onClick={this.handleClick}>{text}</a>
    )
  }
}
export default DownloadLink;